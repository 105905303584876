/* Common Css*/
.form-control:focus {
  border-color: #030844;
  box-shadow: 0 1px 1px rgba(3, 8, 68, 0.075) inset, 0 0 8px rgba(3, 8, 68, 0.3);
}
.btn-primary,
.btn-primary:focus {
  background-color: #030844;
  border-color: #030844 #030844 #030844;
  color: #fff;
}
.btn-primary:hover {
  background-color: #4d4f6a;
  border-color: #4d4f6a #4d4f6a #030844;
  color: #fff;
}

.btn-outline-primary {
  border-color: #030844 #030844 #030844;
  color: #030844;
}

.btn-outline-primary:hover {
  border-color: #030844 #030844 #030844;
  background-color: #030844;
  color: #fff;
}
.innercontent {
  background: #ecedf0;
}
.dashboard-table tr:nth-of-type(even) td {
  background: #fff;
}
.dashboard-table tr:nth-of-type(odd) td {
  background: #f0f0f0;
}
.dashboard-table th,
.dashboard-table td {
  color: #434670;
}
.text-primary {
  color: #434670 !important;
}
th,
td {
  padding: 10px !important;
}
.common-table th {
  background: #f0f0f0;
}
.common-table th,
.common-table td {
  color: #434670;
}
.common-table td {
  height: 50px;
}

.common-table tr:nth-of-type(even) td {
  background: #f0f0f0;
}
.common-table tr:nth-of-type(odd) td {
  background: #fff;
}
.page-link {
  border: 1px solid #dddddd;
}
.page-link:hover {
  background-color: #434670;
  border-color: #434670;
  color: #fff;
}
.search {
  position: relative;
}
.search i {
  position: absolute;
  left: 7px;
  right: auto;
  top: 13px;
  font-size: 18px;
}
.search input {
  padding: 8px 8px 8px 25px;
  width: 150px;
  border-radius: 25px;
}
.form-group {
  border: 0 !important;
}
.sub-title h5 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: normal;
}
.sd-container {
  position: relative;
  float: right;
}

.sd {
  font-size: 21px;
  border: 0;
  width: 25px;
  height: 25px;
  background: transparent;
  padding: 0;
  outline: 0;
  color: #ecedf0;
}

.open-button {
  position: absolute;
  top: 10px;
  right: 3px;
  width: 25px;
  height: 25px;
}

.open-button button {
  border: none;
  background: transparent;
}

.open-button button i {
  font-size: 25px;
}

.sidebar-left-collapsed .page-header {
  left: 150px;
}

.title {
  font-size: 18px;
  font-weight: 400;
  color: #434670;
}
.sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #434670;
}
.courtcheckbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
}

/* Hide the browser's default radio button */
.courtcheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #030844;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.courtcheckbox input:checked ~ .checkmark {
  background-color: #030844;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.courtcheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.courtcheckbox .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.with-judgement .courtcheckbox {
  margin-bottom: 0;
  font-size: 12px;
  padding-left: 22px;
}

.with-judgement .courtcheckbox .checkmark {
  width: 15px;
  height: 15px;
  top: 3px;
}

.with-judgement .courtcheckbox .checkmark:after {
  top: 4px;
  left: 4px;
  width: 5px;
  height: 5px;
}

/*Common Page Css*/
.common-cms-page-content h1,
.common-cms-page-content h2,
.common-cms-page-content h3,
.common-cms-page-content h4,
.common-cms-page-content h5,
.common-cms-page-content h6 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 15px;
  font-weight: bold;
}

.common-cms-page-content ul,
.common-cms-page-content ol {
  margin-left: 0px;
  margin-bottom: 0;
  padding-left: 18px;
  padding-bottom: 10px;
}
.common-cms-page-content ol {
  padding-left: 14px;
}
.common-cms-page-content ul li,
.common-cms-page-content ol li {
  padding-bottom: 5px;
}

.common-cms-page-content p {
  padding-bottom: 10px;
  margin-bottom: 0;
}
.common-cms-page-content .align-img {
  margin-bottom: 15px;
}

.signup-link {
  font-size: 16px;
}

.thumb-info-type {
  background: #030844 !important;
}

@media (max-width: 1260px) {
  .courtcheckbox {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .table-responsive table {
    width: 1000px;
  }
}

@media (min-width: 768px) {
  .page-header {
    position: absolute;
    top: -65px;
    left: -7px;
    background: transparent;
    border: 0;
    box-shadow: unset;
    margin: 0;
  }
  .page-header h2 {
    color: #434670;
    border: 0;
    padding: 0;
  }
}
@media (max-width: 991px) {
  .common-cms-page-content .contact-form {
    order: 2;
  }
  .common-cms-page-content .contact-img {
    order: 1;
    max-width: 300px;
    margin: 0 auto 20px;
  }
}

/*Modal Css*/
.modal h5.modal-title {
  font-size: 18.2px;
  font-weight: 400;
  line-height: 18px;
}
.modal .success-true {
  /* border: 2px solid #030844; */
  background: #030844;
  border-radius: 50%;
  padding: 7px 7px;
  display: block;
  width: max-content;
  margin: 0 auto 20px;
}
.modal i {
  font-size: 30px;
  color: #fff;
  vertical-align: middle;
}
@media (min-width: 1199px) {
  .modal-addcases .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-addcases .modal-dialog .modal-content {
    height: 92vh;
  }
}

/* Sign Up Css */
.body-sign .card-sign .card-body {
  border-top-color: #030844;
}

/*Header Css*/
.header .logo-container .logo img {
  width: 175px;
  height: 85px;
}
@media only screen and (max-width: 767px) {
  .header .logo-container .logo img {
    width: 114px;
    height: 56px;
  }
}

/*Sidebar Css*/
.nav-main.navbarcontent {
  height: calc(100vh - 50px);
  overflow: hidden;
  overflow-y: scroll;
}

.nav-main.navbarcontent::-webkit-scrollbar {
  width: 10px;
}

.nav-main.navbarcontent::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}
ul.nav-main li a.active {
  color: #434670;
  background: #fff;
}

/* Header Css*/
.userbox .dropdown-menu a.active {
  background: #030844;
  color: #fff;
}

/* View User css*/
.user-content .user-logo {
  text-align: center;
}
@media (max-width: 991px) {
  .user-content .user-logo {
    margin-bottom: 20px;
  }
}

/* Cases Document*/
.case-documents ul li span {
  color: #434670;
}

/* Forget Pasword*/
.forget-password-content ul li button {
  background: transparent !important;
  color: #030844 !important;
  border-left: 1px solid #030844 !important;
  border-right: 1px solid #030844 !important;
  border-top: 3px solid #030844 !important;
}
.forget-password-content ul li button.active {
  background: #030844 !important;
  color: #fff !important;
}
.forget-password-content .tab-content {
  border: 1px solid #030844;
}

/*Footer Css*/
.footer {
  background: #fff;
}

.footer ul.menu {
  display: flex;
  align-items: center;
}
.footer ul.menu {
  padding: 15px 0;
  margin: 0;
}
.footer ul.menu li {
  list-style-type: none;
}
.footer ul.menu li a {
  border-right: 1px solid #030844;
  padding: 0 10px;
}
.footer ul.menu li:first-child a {
  padding-left: 0;
}
.footer ul.menu li:last-child a {
  border-right: 0;
  padding-right: 0;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure loader is above other content */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #030844; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.text-container {
  font-size: 16px; /* Adjust font size as needed */
  line-height: 1.5; /* Adjust line height for better readability */
  overflow-wrap: break-word; /* Ensure long words wrap instead of overflowing */
}

.view-more-link {
  color: #007bff; /* Link color */
  cursor: pointer;
  text-decoration: underline;
}

.view-more-link:hover {
  color: #0056b3; /* Hover color */
}
.description-modal {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.description-modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 80%;
  max-height: 60%;
  overflow-y: auto; /* Add scrollbar */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.description-text-container {
  font-size: 16px;
  line-height: 1.5;
}
.w-15 {
  width: 15%;
}
.fc-event-tooltip {
  position: absolute;
  z-index: 999999999;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 200px; /* Adjust the max-width as needed */
  white-space: pre-wrap;
  left: 0
}
.fc-event {
  z-index: 1000; /* Set the desired z-index value */
}